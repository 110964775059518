import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Cabecalho extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        /*   console.log(this.props.revenda); */

        return (
            <header id="header" className="topbar-dark">
                <div className="topbar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="topbar-left text-lg-left text-center">
                                    <ul className="list-inline">
                                        <li>
                                            <i className="fa fa-envelope-o"> </i>
                                            contato@rofemotors.com.br
                                        </li>
                                        <li>
                                            <i className="fa fa-clock-o"></i> Seg - Sex 8:00 ás 18.00. Sab -
                                            9:00 ás 14:00 - Dom - Fechado.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="topbar-right text-lg-right text-center">
                                    <ul className="list-inline">
                                        <li><i className="fa fa-whatsapp"></i> (34) 3225-5990</li>
                                        <li>
                                            <a href="https://www.facebook.com/rofemotorsudia"
                                            ><i className="fa fa-facebook"></i
                                            ></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/rofe_motors/"
                                            ><i className="fa fa-instagram"></i
                                            ></a>
                                        </li>
                                        <li>
                                            <a href="https://www.google.com/maps/place/Rofe+Motors/@-18.9009366,-48.2600967,17z/data=!3m1!4b1!4m5!3m4!1s0x94a445d12f73b749:0x81c30dd0645841d2!8m2!3d-18.9009366!4d-48.2600967"
                                            ><i className="fa fa-map-marker"></i
                                            ></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu">
                    <nav id="menu" className="mega-menu">
                        <section className="menu-list-items">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="menu-logo">
                                            <li>
                                                <a href="index.html"
                                                ><img
                                                        id="logo_dark_img"
                                                        src="images/logo-dark.png"
                                                        alt="logo"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                        <ul className="menu-links">
                                            <li><Link to="/" > Início</Link></li>
                                            <li><Link to="/estoque" > Estoque</Link></li>
                                            <li><Link to="/vendaseuveiculo" > Venda Seu Veículo</Link></li>
                                            <li><Link to="/financiamento" > Financiamento</Link></li>
                                            <li><Link to="/contato" > Contato</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </nav>
                </div>
            </header>

        )
    }

}

export default Cabecalho;